
<template>
<div>
    <!-- 产品卡片 -->
    <div class="banner container-fuild text-center">我们的产品</div>
    <div class="card">
        <div class="box" v-for="(item,index) in tableData" :key="index">
            <img :src="url+item.cover" class="img" alt="">
            <h3>{{item.articleTitle}}</h3>
            <div style="color:#6E6E6E">{{item.gmtCreate | formatDate}}</div>
            <div>{{item.summary}}
              <el-button v-show="isShow" type="text" @click="go(item.id)">了解更多</el-button>
            </div>
        </div>   
    </div>
    <!-- <div> -->
    
    <!-- </div> -->
    <!-- 常见问题 -->
    <div class="answer">
        <el-divider></el-divider>
        <p>常见问题</p>
        <el-collapse accordion>
            <el-collapse-item>
                <template #title>
                <i class="el-icon-view"></i>能用wifi连接设备吗？
                </template>
                <div><i class="el-icon-success"></i>可以的全面支持wifi连接</div>
            </el-collapse-item>
            <el-collapse-item>
                <template #title>
                <i class="el-icon-view"></i>是否有优惠？
                </template>
                <div><i class="el-icon-success"></i>新客户购买或者介绍新客户都是有优惠的呢</div>
            </el-collapse-item>
            <el-collapse-item>
                <template #title>
                <i class="el-icon-view"></i>出问题了又技术售后吗？
                </template>
                <div><i class="el-icon-success"></i>可以的全面支持wifi连接</div>
            </el-collapse-item>
            <el-collapse-item>
                <template #title>
                <i class="el-icon-view"></i>安全方面靠谱吗？
                </template>
                <div><i class="el-icon-success"></i>是的，我们非常重视安全方面问题，保证客户安心使用产品</div>
            </el-collapse-item>
            <el-collapse-item>
                <template #title>
                <i class="el-icon-view"></i>价格昂贵吗？
                </template>
                <div><i class="el-icon-success"></i>价格都是符合市面上的价格要求，可以查询的呢</div>
            </el-collapse-item>
            <el-collapse-item>
                <template #title>
                <i class="el-icon-view"></i>都是最新版本的技术吗？
                </template>
                <div><i class="el-icon-success"></i>是的，我们会第一时间更新版本技术的</div>
            </el-collapse-item>
        </el-collapse>
    </div>
</div>
</template>

<script>
import { get,post} from "../network/request";
import {BASE_URL} from '../network/config/index'
import {formatDate} from '../network/cldate.js'
  export default {
  data() {
    return {
        url:'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png',
        articledata:[],
        url:BASE_URL + '/',
        article:'',
        i:-1,
        isShow:true,
        tableData:[]
    };
  },
  filters:{
      formatDate(time) {
        time = time * 1000
        let date = new Date(time)
        // console.log(new Date(time))
        return formatDate(date, 'yyyy-MM-dd')
      }
    },
  created(){
      console.log('123')
    //   this.getarticle()
      this.id = this.$route.params.id
  },
  mounted(){
      this.getlist(this.id)
  },
  methods: {
    go(id){
      this.$router.push({path:'/details',query:{id:id}})
    },
      // show(index){
      //     this.i = index
      //     this.isShow = false
      // },
      // hide(){
      //     this.i = -1
      //     this.isShow = true
      // },
      Dtails(row) {
        // console.log(row.id);
        this.$router.push({path:'/details',query:{id:row.id}})
      },
      getlist(id){
            get('/ess/ess-article/getAllArticle/' + id + '/1/5',{
            
            }).then(res=>{
            console.log(res)
            if(res.data.code == 200)
            {
                // this.tableData = res.data.data.data
                for(const i of res.data.data.data){
                    // console.log(i.id);
                    get('/ess/ess-article/get/' + i.id,{

                    }).then(res=>{
                        // console.log(res.data.data);
                        this.tableData.push(res.data.data)
                    })
                }
            }
            }).catch(err=>{
            console.log(err)
            })
        },
    
  }
}
</script>

<style scoped lang="less" scoped>
.banner {
  color: #fff;
  font-size: 30px;
  height: 150px;
  line-height: 150px;
  background-image: url("../assets/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
}
.card{
    width: 70%;
    // margin:20px 250px;
    margin: 0 auto;
    >p{
        font-size: 13px;
        color: #999;
    }
  }

.box{
  width: 70%;
  padding-left: 30%;
  padding-top: 25px;
    line-height: 40px;
    font-size: 18px; 
    // font-family: '宋体';
}
  .image {
    width: 100%;
    height: 200px;
    display: block;
  }

  .answer{
      width: 1200px;
      padding-left: 25%;
      margin:40px 180px 20px;
      font-size: 20px;
      color: #999;
  }
.img{
  margin-bottom: 30px;
    width: 550px;
    height: 350px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
}
</style>
